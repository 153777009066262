:root {
  --white: #FBFAF9;
  --dark: #323231;
  --highlight: #1ecb7e;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}