.header {
  background-color: #222220;
  color: #BBB;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.header__title {
  color: #FBFAF9;
  font-size: 3rem;
  line-height: 1.3em;
  font-weight: 300;
  max-width: 75%;
  margin: 5rem auto 0 auto;
}

.header__title--effect {
  font-weight: 600;
  color: var(--highlight);
}

.header__subtitle {
  font-size: 1rem;
  font-weight: 300;
  max-width: 75%;
  margin: 0 auto;
}
